import gql from '../../_snowpack/pkg/graphql-tag.js';
export const GET_NERDY_VIEW_PREFERENCE = gql`
  query GET_NERDY_VIEW_PREFERENCE {
    me {
      id
      preferences {
        experimental {
          nerdyView {
            enabled
          }
        }
      }
    }
  }
`;