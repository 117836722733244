import gql from '../../_snowpack/pkg/graphql-tag.js';
export const GET_TENANT_VAT_TYPES = gql`
  query GET_TENANT_VAT_TYPES($tenantId: ID!) {
    tenant {
      get(id: $tenantId) {
        vatTypes {
          id
          name
          percent
        }
      }
    }
  }
`;