import { useQuery } from '../../_snowpack/pkg/@apollo/react-hooks.js';
import { GET_NERDY_VIEW_PREFERENCE } from '../queries/index.js';
export function useNerdyViewPreference() {
  var _data$me, _data$me$preferences, _data$me$preferences$;

  const {
    error,
    data
  } = useQuery(GET_NERDY_VIEW_PREFERENCE);

  if (error || !(data !== null && data !== void 0 && (_data$me = data.me) !== null && _data$me !== void 0 && (_data$me$preferences = _data$me.preferences) !== null && _data$me$preferences !== void 0 && (_data$me$preferences$ = _data$me$preferences.experimental) !== null && _data$me$preferences$ !== void 0 && _data$me$preferences$.nerdyView)) {
    console.error(`Couldn't retrieve nerdy view preference`, error);
    return null;
  }

  return data.me.preferences.experimental.nerdyView.enabled;
}