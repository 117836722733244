import Add from './add.js';
import Arrow from './arrow.js'; // @ts-expect-error

import BackArrow from './back-arrow.js'; // @ts-expect-error

import Bandwidth from './bandwidth.js';
import Bell from './bell.js';
import BillingAndPayments from './billing-and-payments.js'; // @ts-expect-error

import CalendarColor from './calendar-color.js';
import Cancel from './cancel.js';
import Catalogue from './catalogue.js';
import Chevron from './chevron.js';
import Clock from './clock.js';
import Cloud from './cloud.js';
import CloudWithCross from './cloud-with-cross.js';
import CloudWithKeyHole from './cloud-with-key-hole.js';
import Cog from './cog.js';
import Copy from './copy.js';
import Customers from './customers.js';
import Dashboard from './dashboard.js';
import DashedCube from './dashed-cube.js';
import Document from './document.js';
import Dots from './dots.js'; // @ts-expect-error

import DragCircles from './drag-circles.js'; // @ts-expect-error

import Duplicate from './duplicate.js';
import Edit from './edit.js';
import Folder from './folder.js';
import Fulfilment from './fulfilment.js';
import FulfilmentOrder from './fulfilment-order.js'; // @ts-expect-error

import FulfilmentOrderWhite from './fulfilment-order-white.js';
import Glasses from './glasses.js';
import GraphQL from './graphQL.js';
import Grid from './grid.js';
import Hooks from './hooks.js';
import Warning from './icon-warning.js'; // @ts-expect-error

import Info from './info.js'; // @ts-expect-error

import ItemRelations from './item-relations.js';
import Items from './items.js';
import ListView from './list-view.js';
import LockClosed from './locked-closed.js';
import Magnifier from './magnifier.js'; // @ts-expect-error

import Merge from './merge.js';
import Multilingual from './multilingual.js';
import NailPolish from './nail-polish.js';
import Order from './order.js';
import People from './people.js';
import Percentage from './percentage.js'; // @ts-expect-error

import Pin from './pin.js';
import PlansAndPricing from './plans-and-pricing.js'; // @ts-expect-error

import Price from './price.js';
import PriceTag from './price-tag.js';
import Product from './product.js';
import Renew from './renew.js'; // @ts-expect-error

import RenewDue from './renew-due.js';
import Reorder from './reorder.js';
import Reset from './reset.js';
import Rocket from './rocket.js';
import Shapes from './shapes.js';
import Sort from './sort.js'; // @ts-expect-error

import Split from './split.js'; // @ts-expect-error

import Star from './star.js';
import Stock from './stock.js';
import StockLocation from './stock-location.js';
import Subscription from './subscription.js'; // @ts-expect-error

import SwitchIcon from './switch.js'; // @ts-expect-error

import Target from './target.js';
import Topics from './topic.js'; // @ts-expect-error

import Trashcan from './trashcan.js';
import TrashcanColor from './trashcan-color.js';
import Triangle from './triangle.js';
import Update from './update.js';
import Usage from './usage.js';
import UsageMeter from './usage-meter.js';
import UserAccount from './user-account.js';
import UserCard from './user-card.js';
import Visualize from './visualize.js';
import FileComponent from './file-component.js';
export const Icon = {
  Add,
  Arrow,
  BackArrow,
  Bandwidth,
  Bell,
  BillingAndPayments,
  CalendarColor,
  Cancel,
  Catalogue,
  Chevron,
  Clock,
  Cloud,
  CloudWithCross,
  CloudWithKeyHole,
  Cog,
  Copy,
  Customers,
  Dashboard,
  DashedCube,
  Document,
  Dots,
  DragCircles,
  Duplicate,
  Edit,
  FileComponent,
  Folder,
  Fulfilment,
  FulfilmentOrder,
  FulfilmentOrderWhite,
  Glasses,
  GraphQL,
  Grid,
  Hooks,
  Info,
  ItemRelations,
  Items,
  ListView,
  LockClosed,
  Magnifier,
  Merge,
  Multilingual,
  NailPolish,
  Order,
  People,
  Percentage,
  Pin,
  PlansAndPricing,
  Price,
  PriceTag,
  Product,
  Renew,
  RenewDue,
  Reorder,
  Reset,
  Rocket,
  Shapes,
  Sort,
  Split,
  Star,
  Stock,
  StockLocation,
  Subscription,
  SwitchIcon,
  Target,
  Topics,
  Trashcan,
  TrashcanColor,
  Triangle,
  Update,
  Usage,
  UsageMeter,
  UserAccount,
  UserCard,
  Visualize,
  Warning
};